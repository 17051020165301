<template>
  <div>
    <KTCodePreview v-bind:title="'Thông tin phiếu yêu cầu nhập hàng'">
      <template v-slot:toolbar>
        <div class="row" v-if="mainModel.id">
          <div>
            <b-row
              class="mb-4 ml-4"
              style="vertical-align: middle; display: inline"
            >
              <b-dropdown size="sm" id="dropdown-1" right>
                <template slot="button-content">
                  <i style="font-size: 1rem" class="fas fa-cog"></i>
                  <span class="font-weight-bolder">Thao tác</span>
                </template>
                <b-dropdown-item @click="reportClick">
                  <span>
                    <i style="font-size: 1rem" class="far fa-file-excel"></i>
                    &nbsp; Xuất Excel</span
                  >
                </b-dropdown-item>
              </b-dropdown>
            </b-row>
          </div>
        </div>
      </template>
      <template v-slot:preview>
        <b-card no-body class="full-width">
          <div>
            <div
              class="wizard wizard-4"
              id="kt_wizard_v4"
              data-wizard-state="step-first"
              data-wizard-clickable="true"
            >
              <!--begin: Wizard Nav-->
              <div class="wizard-nav" style="background-color: #eef0f8">
                <div class="wizard-steps">
                  <div
                    class="wizard-step"
                    data-wizard-type="step"
                    data-wizard-state="current"
                  >
                    <div class="wizard-wrapper">
                      <div class="wizard-label">
                        <div class="wizard-title">Thông tin</div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="wizard-step ml-1"
                    data-wizard-type="step"
                    v-show="mainModel.id"
                  >
                    <div class="wizard-wrapper">
                      <div class="wizard-label">
                        <div class="wizard-title">Tệp đính kèm</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card card-custom card-shadowless rounded-top-0">
                <div class="card-body p-0">
                  <div class="row">
                    <div class="col-xl-12">
                      <div
                        class="py-8 px-4 py-lg-15 px-lg-4 pb-5 full-width"
                        data-wizard-type="step-content"
                        data-wizard-state="current"
                      >
                        <b-row class="mb-5">
                          <!-- INFO INPUT CONTROLS -->
                          <b-col cols="6">
                            <b-row v-if="mainModel.id">
                              <b-col>
                                <b-form-group>
                                  <template>
                                    <span>Mã phiếu:</span>
                                  </template>
                                  <p class="mt-2">{{ mainModel.code }}</p>
                                </b-form-group>
                              </b-col>
                              <b-col>
                                <b-form-group>
                                  <template>
                                    <span>Người tạo:</span>
                                  </template>
                                  <p class="mt-2">{{ mainModel.createdBy }}</p>
                                </b-form-group>
                              </b-col>
                            </b-row>
                            <b-row>
                              <b-col>
                                <b-form-group>
                                  <template>
                                    <span>Ngày tạo:</span>
                                  </template>
                                  <p class="mt-2">{{ mainModel.createdAt }}</p>
                                </b-form-group>
                              </b-col>
                              <b-col>
                                <b-form-group>
                                  <template>
                                    <span>Tổng tiền:</span>
                                  </template>
                                  <p class="mt-2">
                                    {{ convertPrice(totalPrice) }}
                                  </p>
                                </b-form-group>
                              </b-col>
                            </b-row>

                            <b-row>
                              <b-col>
                                <b-form-group
                                  class="required-control"
                                  :disabled="!editable"
                                >
                                  <label>Kho:</label>
                                  <Autosuggest
                                    :disabled="!isModeCreateNew"
                                    class="border-radius-none"
                                    :model="mainModel.storeName"
                                    :suggestions="filteredOptionStores"
                                    placeholder="kho"
                                    @select="onSelectedStore"
                                    @change="onInputChangeStore"
                                    suggestionName="suggestionName"
                                  />
                                </b-form-group>
                              </b-col>
                              <b-col md="6">
                                <b-form-group
                                  class="required-control"
                                  :disabled="!editable"
                                >
                                  <label>Nhà cung cấp:</label>
                                  <div class="float-right">
                                    <span
                                      @click="
                                        showProviderModal = !showProviderModal
                                      "
                                      ><i
                                        class="
                                          fa fa-plus-circle
                                          text-primary
                                          pt-1
                                        "
                                        style="cursor: pointer"
                                      ></i
                                    ></span>
                                  </div>
                                  <Autosuggest
                                    class="border-radius-none"
                                    :model="mainModel.providerName"
                                    :suggestions="filteredOptionsProvider"
                                    placeholder="nhà cung cấp"
                                    :limit="200"
                                    @select="onSelectedProvider"
                                    @change="onInputChangeProvider"
                                    suggestionName="suggestionName"
                                  />
                                </b-form-group>
                              </b-col>
                            </b-row>

                            <b-row>
                              <b-col>
                                <b-form-group :disabled="!editable">
                                  <label>Lý do nhập hàng:</label>
                                  <b-form-textarea
                                    size="sm"
                                    v-model="mainModel.importReason"
                                    :placeholder="'Nhập lý do nhập hàng...'"
                                    :rows="6"
                                    :max-rows="6"
                                  ></b-form-textarea>
                                </b-form-group>
                              </b-col>
                            </b-row>
                            <b-row>
                              <b-col>
                                <b-form-group :disabled="!editable">
                                  <label>Ghi chú:</label>
                                  <b-form-textarea
                                    size="sm"
                                    v-model="mainModel.description"
                                    :placeholder="'Thêm nội dung ghi chú...'"
                                    :rows="6"
                                    :max-rows="6"
                                  ></b-form-textarea>
                                </b-form-group>
                              </b-col>
                            </b-row>
                          </b-col>
                          <!-- INFO VAT INPUT CONTROLS -->
                          <b-col cols="3">
                            <b-form-group :disabled="!editable">
                              <label>Doanh nghiệp:</label>
                              <b-form-select
                                class="select-style"
                                v-model="mainModel.companyId"
                                :options="listCompany"
                                size="sm"
                                value-field="id"
                                text-field="name"
                              >
                              </b-form-select>
                            </b-form-group>
                            <b-form-group :disabled="!editable">
                              <label>Hình thức thanh toán:</label>
                              <b-form-select
                                class="select-style"
                                v-model="mainModel.importType"
                                :options="listImportType"
                                size="sm"
                                value-field="id"
                                text-field="name"
                              >
                              </b-form-select>
                            </b-form-group>
                            <b-form-group :disabled="!editable">
                              <label style="margin-bottom: 0.75rem"
                                >Chiết khấu:</label
                              >
                              <b-row>
                                <b-col class="pr-0" cols="5">
                                  <b-form-select
                                    class="select-style"
                                    v-model="mainModel.discountType"
                                    :options="listAmountType"
                                    size="sm"
                                    value-field="id"
                                    text-field="name"
                                  >
                                  </b-form-select>
                                </b-col>
                                <b-col class="pl-0" cols="7">
                                  <b-form-input
                                    class="input-style text-right"
                                    size="sm"
                                    v-model="mainModel.discountAmount"
                                    v-mask="formater.currency"
                                    placeholder="Chiết khấu"
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                            </b-form-group>
                            <POVat
                              :editable="true"
                              :mainModel="mainModel"
                              v-if="renderComponent"
                            />
                          </b-col>
                          <!-- PAYMENT INPUT CONTROLS -->
                          <b-col cols="3">
                            <POPayment
                              :editable="true"
                              :mainModel="mainModel"
                              v-if="renderComponent"
                            />
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col md="3">
                            <b-form-group :disabled="!editable">
                              <label>Người liên hệ:</label>
                              <Autosuggest
                                class="border-radius-none"
                                :model="mainModel.inChargeEmployeeName"
                                :suggestions="filteredOptionsEmployee"
                                placeholder="người liên hệ"
                                :limit="10"
                                @select="onSelectedEmployee"
                                @change="onInputChangeEmployee"
                                suggestionName="suggestionName"
                              />
                            </b-form-group>
                          </b-col>
                        </b-row>
                        <b-row class="mb-4" v-if="editable">
                          <b-col md="2" class="pr-0">
                            <b-form-select
                              v-model="selectTypeSearch"
                              :options="listTypeSearch"
                              size="sm"
                              value-field="id"
                              text-field="name"
                            ></b-form-select>
                          </b-col>
                          <b-col md="6" class="pl-0 pr-0">
                            <treeselect
                              :load-options="loadOptions"
                              :multiple="true"
                              :async="true"
                              :disabled="!mainModel.storeId"
                              placeholder="Tìm kiếm theo tên, mã, mã vạch sản phẩm"
                              retryText="Thử lại..."
                              retryTitle="Nhấp thử lại"
                              searchPromptText="Nhập để tìm kiếm..."
                              loadingText="Đang tải..."
                              noResultsText="Không có kết quả"
                              :match-keys="['label', 'customLabel']"
                              v-model="selectedProducts"
                              :values="searchProduct"
                              :backspace-removes="false"
                              :clearable="false"
                              valueFormat="object"
                              :max-height="200"
                              @select="onSelectedProduct"
                            >
                              <label
                                slot="option-label"
                                slot-scope="{ node, labelClassName }"
                                :class="labelClassName"
                                class="border-bottom"
                              >
                                <span
                                  class="text-primary d-block px-0"
                                  v-if="node.raw.label"
                                >
                                  {{ node.raw.label }}
                                </span>
                                <b
                                  v-if="node.raw.customLabel"
                                  class="d-block px-0"
                                >
                                  {{ node.raw.customLabel }}</b
                                >
                              </label>
                            </treeselect>
                          </b-col>
                          <b-col md="1">
                            <b-button
                              style="width: 40px"
                              variant="secondary"
                              size="sm"
                              v-b-tooltip.hover
                              title="Thêm sản phẩm"
                              @click="onAddProduct"
                            >
                              <i class="fas fa-arrow-down"></i>
                            </b-button>
                          </b-col>
                          <b-col md="3">
                            <div class="d-flex justify-content-end">
                              <b-button
                                variant="secondary"
                                size="sm"
                                class="font-weight-bolder"
                                @click="showModalImportProduct"
                                :disabled="!mainModel.storeId"
                                v-if="editable"
                              >
                                <i
                                  style="font-size: 1rem"
                                  class="far fa-file-excel"
                                ></i
                                >Nhập SP từ excel
                              </b-button>
                            </div>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col md="8">
                            <span class="font-weight-bolder lable-size"
                              >Danh sách sản phẩm:</span
                            >
                          </b-col>
                          <b-col md="4">
                            <b-checkbox
                              v-model="mainModel.isVAT"
                              class="d-flex justify-content-end lable-size"
                              @change="onChangeVat"
                              >Đơn giá đã bao gồm VAT
                            </b-checkbox>
                          </b-col>
                        </b-row>
                        <b-table
                          :fields="fieldProduct"
                          :items="listProductStock"
                          v-if="renderComponent"
                          hover
                          bordered
                          class="mt-4"
                        >
                          <template v-slot:cell(productName)="row">
                            <span
                              v-text="row.item.barCode"
                              style="font-size: 11px"
                              v-show="row.item.barCode"
                            ></span>
                            <br />
                            <span
                              v-text="row.item.productCode"
                              style="font-size: 12px"
                              v-show="row.item.productCode"
                            ></span>
                            <br />
                            <span
                              v-text="row.item.productName"
                              style="white-space: normal; color: #3699ff"
                            ></span>
                          </template>
                          <template v-slot:cell(quantity)="row">
                            <span
                              v-if="
                                mainModel.status ===
                                PURCHASE_ORDER_STATUS.ACCOUNTANT
                              "
                              >{{ convertPrice(row.item.quantity) }}</span
                            >
                            <b-input
                              v-else
                              type="text"
                              size="sm"
                              class="input-style text-right"
                              v-model="row.item.quantity"
                              v-mask="formater.currency"
                              @change="onChangeItemData(row.item.id)"
                            ></b-input>
                          </template>
                          <template v-slot:cell(quantityApprove)="row">
                            <b-input
                              v-if="
                                mainModel.status ===
                                PURCHASE_ORDER_STATUS.ACCOUNTANT
                              "
                              type="text"
                              size="sm"
                              class="input-style text-right"
                              v-model="row.item.quantityApprove"
                              v-mask="formater.currency"
                            ></b-input>
                            <span v-else>{{
                              convertPrice(row.item.quantityApprove)
                            }}</span>
                          </template>
                          <template v-slot:cell(unitPrice)="row">
                            <span
                              v-if="
                                mainModel.status ===
                                PURCHASE_ORDER_STATUS.ACCOUNTANT
                              "
                              >{{ convertPrice(row.item.unitPrice) }}</span
                            >
                            <b-input
                              v-else
                              type="text"
                              size="sm"
                              class="input-style text-right"
                              v-model="row.item.unitPrice"
                              v-mask="formater.currency"
                              @change="onChangeItemData(row.item.id)"
                            ></b-input>
                          </template>
                          <template v-slot:cell(discountAmount)="row">
                            <span
                              v-if="
                                mainModel.status ===
                                PURCHASE_ORDER_STATUS.ACCOUNTANT
                              "
                              >{{ convertPrice(row.item.discountAmount) }}</span
                            >
                            <b-input
                              v-else
                              type="text"
                              size="sm"
                              class="input-style text-right"
                              v-model="row.item.discountAmount"
                              v-mask="formater.currency"
                              @change="onChangeItemData(row.item.id)"
                            ></b-input>
                          </template>
                          <template v-slot:cell(salePrice)="row">
                            <span
                              v-if="
                                mainModel.status ===
                                PURCHASE_ORDER_STATUS.ACCOUNTANT
                              "
                              >{{ convertPrice(row.item.salePrice) }}</span
                            >
                            <b-input
                              v-else
                              type="text"
                              size="sm"
                              class="input-style text-right"
                              v-model="row.item.salePrice"
                              v-mask="formater.currency"
                              @change="onChangeItemData(row.item.id)"
                            ></b-input>
                          </template>
                          <template v-slot:cell(actions)="row">
                            <v-icon
                              small
                              class="text-danger text-center"
                              @click="deleteItemInArray(row.item.id)"
                              v-b-tooltip
                              title="Xóa"
                              >mdi-delete</v-icon
                            >
                          </template>
                        </b-table>
                        <hr
                          class="hr-text"
                          data-content="Bút toán"
                          style="font-weight: 600"
                          v-if="mainModel.id"
                        />
                        <b-row v-if="mainModel.code">
                          <b-col>
                            <StockSlipTransactions
                              :stockCode="mainModel.code"
                            />
                          </b-col>
                        </b-row>
                        <hr
                          class="hr-text"
                          data-content="Phiếu nhập xuất kho"
                          style="font-weight: 600"
                          v-if="checkViewStockSlip()"
                        />
                        <b-row v-if="checkViewStockSlip()">
                          <b-col>
                            <POTransaction :purchaseOrderId="mainModel.id" />
                          </b-col>
                        </b-row>
                        <hr
                          class="hr-text"
                          data-content="Lý do hủy phiếu"
                          style="font-weight: 600"
                        />
                        <b-row v-if="mainModel.code">
                          <b-col>
                            <CancelReason :stockCode="mainModel.code" />
                          </b-col>
                        </b-row>

                        <div class="col-md-12" style="padding-left: 0px">
                          <div class="mb-5 mt-5">
                            <b-button
                              v-if="checkEdit()"
                              v-bind:style="btnCreate"
                              size="sm"
                              variant="primary"
                              class="font-weight-bolder mr-4"
                              @click="onSubmit"
                            >
                              Lưu
                            </b-button>
                            <b-button
                              v-bind:style="btnCreate"
                              size="sm"
                              variant="secondary"
                              class="font-weight-bolder"
                              @click="onBack"
                            >
                              Hủy
                            </b-button>
                          </div>
                          <b-modal
                            v-model="showProviderModal"
                            hide-footer
                            title="Thêm mới nhà cung cấp"
                          >
                            <b-row class="mb-5">
                              <b-col>
                                <label class="labelInput"
                                  >Tên nhà cung cấp:</label
                                >
                                <b-form-input
                                  v-model="providerName"
                                  size="sm"
                                  placeholder="Nhập tên nhà cung cấp"
                                ></b-form-input>
                              </b-col>
                              <b-col>
                                <label class="labelInput"
                                  >Số điện thoại nhà cung cấp:</label
                                >
                                <b-form-input
                                  v-model="providerPhone"
                                  size="sm"
                                  placeholder="Nhập số điện thoại nhà cung cấp"
                                ></b-form-input>
                              </b-col>
                            </b-row>
                            <b-row>
                              <b-col>
                                <b-button
                                  style="fontweight: 600; width: 70px"
                                  variant="primary"
                                  size="sm"
                                  @click="createProvider()"
                                  >Lưu</b-button
                                >
                                <b-button
                                  @click="
                                    showProviderModal = !showProviderModal
                                  "
                                  style="
                                    margin-left: 10px;
                                    font-weight: 600;
                                    width: 70px;
                                  "
                                  variant="secondary"
                                  size="sm"
                                  >Hủy</b-button
                                >
                              </b-col>
                            </b-row>
                          </b-modal>
                          <ImportProductPOModal
                            ref="stock-slip-excel"
                            :storeId="mainModel.storeId"
                            v-on:validData="validData"
                          />
                        </div>
                      </div>

                      <div
                        class="py-4 mb-5 full-width"
                        data-wizard-type="step-content"
                      >
                        <div class="upload-file col-12">
                          <UploadFile
                            v-if="mainModel.id"
                            :id="mainModel.id"
                            :entity="'purchase-orders'"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--end: Wizard Nav -->
        </b-card>
      </template>
    </KTCodePreview>
  </div>
</template>

<style>
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: middle;
  border-top: 1px solid #ecf0f3;
}
.table.b-table.table-sm > thead > tr > [aria-sort]:not(.b-table-sort-icon-left),
.table.b-table.table-sm
  > tfoot
  > tr
  > [aria-sort]:not(.b-table-sort-icon-left) {
  background-position: right calc(1.5rem / 2) center;
  padding-right: calc(0.3rem + 0.65em);
}
</style>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import moment from 'moment';
import ApiService from '@/core/services/api.service';
import { validationMixin } from 'vuelidate';
import {
  currencyMask,
  unMaskPrice,
  convertPrice,
  makeToastFaile,
  makeToastSuccess,
} from './../../../utils/common';
import {
  BASE_URL,
  PURCHASE_ORDER_TYPE,
  TIME_TRIGGER,
} from './../../../utils/constants';
import {
  AMOUNT_TYPE,
  PRODUCT_TYPE,
  PURCHASE_ORDER_STATUS,
} from './../../../utils/enum';
import axios from 'axios';

import decounce from 'debounce';
import ImportProductPOModal from '../../components/purchase-orders/ImportProductPOModal';
import StockSlipTransactions from '../../components/StockSlipTransactions';
import POTransaction from '../../components/POTransaction';
import CancelReason from '../../components/stock-slips/CancelReason';
import { v4 } from 'uuid';
import KTWizard from '@/assets/js/components/wizard';
import KTUtil from '@/assets/js/components/util';
import { xoa_dau } from './../../../utils/common';
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import { ASYNC_SEARCH } from '@riophae/vue-treeselect';
import fileDownload from '../../../utils/file-download';
import UploadFile from '@/view/modules/upload/Upload';
import POPayment from '../../components/purchase-orders/POPayment';
import POVat from '../../components/purchase-orders/POVat';
import Autosuggest from '@/view/base/auto-sugguest/AutoSuggest.vue';

export default {
  mixins: [validationMixin],
  data() {
    return {
      btnCreate: {
        fontWeight: '600!important',
        'margin-right': '-10px',
        width: '70px',
      },
      isNew: true,
      searchProduct: '',
      listTypeSearch: [
        {
          id: null,
          name: 'Tất cả loại sản phẩm',
        },
        {
          id: 1,
          name: 'Sản phẩm',
        },
        {
          id: 2,
          name: 'Sản phẩm IMEI',
        },
      ],
      selectTypeSearch: null,
      listProductStock: [],
      filteredOptionStores: [],
      optionStores: [],
      formater: {
        currency: currencyMask,
      },
      showProviderModal: false,
      providerName: '',
      providerPhone: '',
      filteredOptionsProvider: [],
      optionsProvider: [],
      isSearching: false,
      listAmountType: [
        {
          id: 1,
          name: 'Tiền mặt',
        },
        {
          id: 2,
          name: '%',
        },
      ],
      fieldProduct: [
        {
          key: 'productName',
          label: 'Sản phẩm',
          sortable: false,
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            width: '15%',
          },
          tdClass: 'text-left',
        },
        {
          key: 'inStockQuantity',
          label: 'Tồn',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '5%' },
          tdClass: 'text-right',
        },
        {
          key: 'prevUnitPrice',
          label: 'Giá nhấp nhất',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '10%' },
          tdClass: 'text-right',
          formatter: (value) => {
            return value ? convertPrice(value) : 0;
          },
        },
        {
          key: 'salePowerWeek',
          label: 'Sức bán tuần',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '8%' },
          tdClass: 'text-right',
        },
        {
          key: 'salePowerMonth',
          label: 'Sức bán tháng',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '8%' },
          tdClass: 'text-right',
        },
        {
          key: 'quantity',
          label: 'SL',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '7%' },
          tdClass: 'text-right',
        },
        {
          key: 'quantityApprove',
          label: 'SL đáp ứng',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '7%' },
          tdClass: 'text-right',
        },
        {
          key: 'unitPrice',
          label: 'Đơn giá (VAT)',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '12%' },
          tdClass: 'text-right',
        },
        {
          key: 'totalAmount',
          label: 'Thành tiền',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '11%' },
          tdClass: 'text-right',
          formatter: (value) => {
            return value ? convertPrice(value) : 0;
          },
        },
        {
          key: 'salePrice',
          label: 'Đơn giá bán',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '12%' },
          tdClass: 'text-right',
          formatter: (value) => {
            return value ? convertPrice(value) : 0;
          },
        },
      ],
      renderComponent: true,
      mainModel: {
        id: null,
        code: '',
        status: 1,
        providerId: null,
        providerName: '',
        description: '',
        storeId: null,
        storeName: '',
        discountAmount: 0,
        discountType: 1,
        vatAmount: 0,
        vatType: 2,
        cashAmount: 0,
        cashAccountCode: '',
        cashAccountName: '',
        transferAmount: 0,
        transferAccountCode: '',
        transferAccountName: '',
        payDate: null,
        vatCode: '',
        vatDate: '',
        createdAt: '',
        createdBy: '',
        importType: 1,
        importReason: '',
        isNew: true,
        companyId: null,
        isVAT: true,
        inChargeEmployeeName: '',
        inChargeEmployeeId: null,
      },
      PURCHASE_ORDER_STATUS: PURCHASE_ORDER_STATUS,
      listImportType: PURCHASE_ORDER_TYPE,
      hashName: null,
      filteredOptionsEmployee: [],
      optionsEmployee: [],
      searchEmployee: '',
      listEmployeeSearch: [],
      selectedProducts: [],
      listCompany: [],
    };
  },
  components: {
    KTCodePreview,
    ImportProductPOModal,
    StockSlipTransactions,
    POTransaction,
    CancelReason,
    Treeselect,
    UploadFile,
    POPayment,
    POVat,
    Autosuggest,
  },
  created() {
    this.fetchStoreByUser();
    this.fetchProvider();
    this.fetchEmployee();
    this.fetchCompany();
  },
  mounted() {
    this.mainModel.createdAt = moment().format('DD/MM/YYYY');
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Phiếu yêu cầu nhập hàng', route: '/purchase-orders' },
      { title: 'Thông tin phiếu yêu cầu nhập hàng' },
    ]);
    if (this.$route.query.id) {
      this.mainModel.id = this.$route.query.id;
      this.mainModel.isNew = !!this.$route.query.isCreate;
      this.getInfoById();
    } else {
      this.fieldProduct.push({
        key: 'actions',
        label: '',
        thStyle: {
          fontWeight: 600,
          color: '#181c32',
          textAlign: 'center',
          width: '5%',
        },
        tdClass: 'text-center',
      });
    }
    // Initialize form wizard
    const hashName = this.$route.hash ? this.$route.hash.split('#')[1] : '';

    this.hashName = hashName === 'attach' ? 'attach' : null;

    const wizard = new KTWizard('kt_wizard_v4', {
      startStep: hashName === 'attach' ? 3 : 1, // initial active step number
      clickableSteps: true, // allow step clicking
    });

    wizard.on('beforeNext', function (/*wizardObj*/) {});

    wizard.on('change', function (/*wizardObj*/) {
      setTimeout(() => {
        KTUtil.scrollTop();
      }, 500);
    });
  },
  computed: {
    totalPrice() {
      let total = 0;
      for (let index = 0; index < this.listProductStock.length; index++) {
        const element = this.listProductStock[index];
        total +=
          unMaskPrice(element.unitPrice) * unMaskPrice(element.quantity) -
          unMaskPrice(element.discountAmount);
      }

      const discountValue = this.mainModel.discountAmount
        ? unMaskPrice(this.mainModel.discountAmount)
        : 0;
      let discountAmount = discountValue;
      if (this.mainModel.discountType === AMOUNT_TYPE.PERCENT) {
        discountAmount = (discountValue * total) / 100;
      }

      total = total - discountAmount;
      const vatValue = this.mainModel.vatAmount
        ? unMaskPrice(this.mainModel.vatAmount)
        : 0;

      let vatAmount = vatValue;
      if (this.mainModel.vatType === AMOUNT_TYPE.PERCENT) {
        vatAmount = (vatValue * total) / 100;
      }

      total = parseInt(total) + parseInt(vatAmount);
      return total;
    },
    totalDiscount() {
      let total = 0;
      for (let index = 0; index < this.listProductStock.length; index++) {
        const element = this.listProductStock[index];
        total += unMaskPrice(element.discountAmount) * 1;
      }
      return total;
    },
    totalQuantity() {
      let total = 0;
      for (let index = 0; index < this.listProductStock.length; index++) {
        const element = this.listProductStock[index];
        total += element.quantity * 1;
      }
      return total;
    },
    editable() {
      if (
        this.mainModel.status === PURCHASE_ORDER_STATUS.NEW ||
        this.mainModel.status === PURCHASE_ORDER_STATUS.PO_HEAD_CANCEL ||
        this.mainModel.status === PURCHASE_ORDER_STATUS.ACCOUNTANT_CANCEL
      ) {
        return true;
      }

      return false;
    },
    isModeCreateNew(){
      return !this.$route.query.id;
    }
  },
  methods: {
    convertPrice,
    forceRerender() {
      this.renderComponent = false;

      this.$nextTick(() => {
        this.renderComponent = true;
      });
    },
    onSelectedProduct(option) {
      this.getMinPriceByProductId(option.id);
    },
    onInputChangProduct(text) {
      if (!text) {
        text = '';
      }
      this.searchProduct = text;
      this.debounceInputProduct();
    },
    onSubmit: async function () {
      if (!this.mainModel.providerId) {
        makeToastFaile('Vui lòng chọn nhà cung cấp!');
        return;
      }

      if (!this.listProductStock || !this.listProductStock.length) {
        makeToastFaile('Vui lòng nhập sản phẩm!');
        return;
      }

      if (this.mainModel.id) {
        this.onUpdate();
      } else {
        this.onCreate();
      }
    },
    onCreate() {
      const listPro = this.listProductStock.map((element) => {
        return {
          productId: element.productId,
          quantity: element.quantity ? unMaskPrice(element.quantity) : 0,
          unitPrice: element.unitPrice ? unMaskPrice(element.unitPrice) : 0,
          discountAmount: element.discountAmount
            ? unMaskPrice(element.discountAmount)
            : 0,
          salePowerWeek: element.salePowerWeek,
          salePowerMonth: element.salePowerMonth,
          prevUnitPrice: element.prevUnitPrice,
          quantityApprove: element.quantityApprove
            ? unMaskPrice(element.quantityApprove)
            : 0,
          salePrice: element.salePrice ? unMaskPrice(element.salePrice) : 0,
        };
      });

      const data = {
        storeId: this.mainModel.storeId,
        providerId: this.mainModel.providerId,
        description: this.mainModel.description,
        importReason: this.mainModel.importReason,
        importType: this.mainModel.importType,
        inChargeEmployeeId: this.mainModel.inChargeEmployeeId,
        companyId: this.mainModel.companyId,
        listDetail: listPro,
        cashAmount: this.mainModel.cashAmount
          ? unMaskPrice(this.mainModel.cashAmount)
          : 0,
        cashAccountCode: this.mainModel.cashAccountCode,
        transferAmount: this.mainModel.transferAmount
          ? unMaskPrice(this.mainModel.transferAmount)
          : 0,
        transferAccountCode: this.mainModel.transferAccountCode,
        payDate: this.mainModel.payDate
          ? moment(this.mainModel.payDate, 'DD/MM/YYYY').format('yyyy-MM-DD')
          : null,
        vatDate: this.mainModel.vatDate
          ? moment(this.mainModel.vatDate, 'DD/MM/YYYY').format('yyyy-MM-DD')
          : null,
        vatType: this.mainModel.vatType,
        vatAmount: this.mainModel.vatAmount
          ? unMaskPrice(this.mainModel.vatAmount)
          : 0,
        vatCode: this.mainModel.vatCode,
        discountType: this.mainModel.discountType,
        discountAmount: this.mainModel.discountAmount
          ? unMaskPrice(this.mainModel.discountAmount)
          : 0,
        isVAT: this.mainModel.isVAT,
      };
      if (this.isNew === true) {
        this.isNew = false;
        ApiService.post('purchase-order', data)
          .then(({ data }) => {
            if (data.status === 1) {
              makeToastSuccess(data.message);
              this.onBack();
            } else {
              this.isNew = true;
              makeToastFaile(data.message);
            }
          })
          .catch((response) => {
            this.isNew = true;
            makeToastFaile(response.message);
          });
      }
    },
    onUpdate() {
      let listPro = this.listProductStock.map((element) => {
        return {
          id: element.id,
          productId: element.productId,
          quantity: element.quantity ? unMaskPrice(element.quantity) : 0,
          unitPrice: element.unitPrice ? unMaskPrice(element.unitPrice) : 0,
          discountAmount: element.discountAmount
            ? unMaskPrice(element.discountAmount)
            : 0,
          isNew: element.isNew,
          salePowerWeek: element.salePowerWeek,
          salePowerMonth: element.salePowerMonth,
          prevUnitPrice: element.prevUnitPrice,
          quantityApprove: element.quantityApprove
            ? unMaskPrice(element.quantityApprove)
            : 0,
          salePrice: element.salePrice ? unMaskPrice(element.salePrice) : 0,
        };
      });

      listPro.map((x) => {
        if (x.isNew) delete x.id;

        delete x.isNew;
      });

      const data = {
        id: this.mainModel.id,
        providerId: this.mainModel.providerId,
        description: this.mainModel.description,
        importReason: this.mainModel.importReason,
        inChargeEmployeeId: this.mainModel.inChargeEmployeeId,
        companyId: this.mainModel.companyId,
        type: this.mainModel.importType,
        listDetail: listPro,
        cashAmount: this.mainModel.cashAmount
          ? unMaskPrice(this.mainModel.cashAmount)
          : 0,
        cashAccountCode: this.mainModel.cashAccountCode,
        transferAmount: this.mainModel.transferAmount
          ? unMaskPrice(this.mainModel.transferAmount)
          : 0,
        transferAccountCode: this.mainModel.transferAccountCode,
        payDate: this.mainModel.payDate
          ? moment(this.mainModel.payDate, 'DD/MM/YYYY').format('yyyy-MM-DD')
          : null,
        vatDate: this.mainModel.vatDate
          ? moment(this.mainModel.vatDate, 'DD/MM/YYYY').format('yyyy-MM-DD')
          : null,
        vatType: this.mainModel.vatType,
        vatAmount: this.mainModel.vatAmount
          ? unMaskPrice(this.mainModel.vatAmount)
          : 0,
        vatCode: this.mainModel.vatCode,
        discountType: this.mainModel.discountType,
        discountAmount: this.mainModel.discountAmount
          ? unMaskPrice(this.mainModel.discountAmount)
          : 0,
        isVAT: this.mainModel.isVAT,
      };
      if (this.isNew === true) {
        this.isNew = false;
        ApiService.put('purchase-order', data)
          .then(({ data }) => {
            if (data.status === 1) {
              this.isNew = true;
              makeToastSuccess(data.message);
              setTimeout(() => {
                this.$router.push({
                  name: 'list-purchase-orders',
                });
                this.onBack();
              }, TIME_TRIGGER);
            } else {
              this.isNew = true;
              makeToastFaile(data.message);
            }
          })
          .catch((response) => {
            this.isNew = true;
            makeToastFaile(response.message);
          });
      }
    },
    fetchProvider() {
      ApiService.get(`providers/getAll`).then(({ data }) => {
        const providers = data.data.providers;
        this.optionsProvider = providers.map((element) => {
          return {
            id: element.id,
            name: element.name,
            suggestionName: element.name,
            numPaymentDate: element.numPaymentDate,
          };
        });
        this.filteredOptionsProvider = [...this.optionsProvider];
      });
    },
    fetchProduct(textSearch) {
      this.isSearching = true;

      const params = {
        storeId: this.mainModel.storeId,
        searchProduct: textSearch,
        searchType: this.selectTypeSearch,
      };

      return ApiService.query('purchase-order/search-product', { params }).then(
        ({ data }) => {
          const products = data.data;
          return products;
        }
      );
    },
    deleteItemInArray(id) {
      for (var i = 0; i < this.listProductStock.length; i++) {
        if (this.listProductStock[i].id === id) {
          this.listProductStock.splice(i, 1);
        }
      }
    },
    onChangeStore() {
      if (this.mainModel.isNew && !this.$route.query.isCreate) {
        this.listProductStock = [];
        this.selectedProducts = [];
      } else {
        const productIds = this.listProductStock.map(
          (productStock) => productStock.productId
        );
        this.getPowerSaleByProductId(productIds);
      }
    },
    onSelectedStore(option) {
      this.mainModel.storeName = option.item.name;
      this.mainModel.storeId = option.item.id;
      this.onChangeStore();
    },
    onInputChangeStore(text) {
      this.mainModel.storeName = text;
      const filteredData = this.optionStores.filter(
        item =>
          item.name.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
          item.shortName.toLowerCase().indexOf(text.toLowerCase()) > -1
      );
      this.filteredOptionStores = filteredData;
    },
    onSelectedProvider(option) {
      this.mainModel.providerName = option.item.name;
      this.mainModel.providerId = option.item.id;
      const numDate = option.item.numPaymentDate;
      if (numDate > 0) {
        this.mainModel.payDate = moment()
          .add(numDate, 'days')
          .format('DD/MM/YYYY');
      }
      if (!numDate) {
        this.mainModel.payDate = '';
      }
    },
    onInputChangeProvider(text) {
      this.mainModel.providerName = text;
      const filteredData = this.optionsProvider
        .filter((item) => {
          return item.name.toLowerCase().indexOf(text.toLowerCase()) > -1;
        })
        .slice(0, 10);
      this.filteredOptionsProvider = [...filteredData];
    },
    createProvider: async function () {
      if (!this.providerName) {
        return;
      }

      const data = {
        name: this.providerName,
        phoneNo: this.providerPhone,
        status: 1,
      };

      ApiService.post('providers', data).then((response) => {
        const { status, message } = response.data.data;
        if (status === 1) {
          this.fetchProvider();
          this.showProviderModal = !this.showProviderModal;
          makeToastSuccess(message);
        } else {
          this.showProviderModal = !this.showProviderModal;
          makeToastFaile(message);
        }
      });
    },
    searchProductAPI() {
      if (!this.isSearching) {
        const textSearch = this.searchProduct;
        this.fetchProduct(textSearch);
      }
    },
    debounceInputProduct: decounce(function () {
      this.searchProductAPI();
    }, TIME_TRIGGER),
    fetchStoreByUser() {
      ApiService.get(`stores/get-by-user`).then(({ data }) => {
        if (data.status === 1) {
          const stores = data.data.stores;
          if (stores.length === 1) {
            this.mainModel.storeId = stores[0].id;
            this.mainModel.storeName = stores[0].name;
          } else {
            this.optionStores = stores.map((element) => {
              return {
                id: element.id,
                name: element.name,
                shortName: element.shortName,
                suggestionName: element.shortName + ' ( ' + element.name + ')',
              };
            });
            this.filteredOptionStores = [...this.optionStores];
          }
        }
      });
    },
    showModalImportProduct() {
      this.$refs['stock-slip-excel'].showModal();
    },
    validData(data) {
      this.listProductStock = this.listProductStock.concat(data);
    },
    onChangeItemData(id) {
      const item = this.listProductStock.find((x) => x.id === id);
      item.totalAmount =
        unMaskPrice(item.unitPrice) * unMaskPrice(item.quantity) -
        unMaskPrice(item.discountAmount);
      item.quantityApprove = item.quantity ? unMaskPrice(item.quantity) : 0;
    },
    onBack() {
      this.$router.push({
        name: 'list-purchase-orders',
      });
    },
    getInfoById() {
      ApiService.get(`purchase-order/${this.mainModel.id}`)
        .then(({ data }) => {
          this.mainModel.status = data.data.status
            ? parseInt(data.data.status)
            : 1;
          this.mainModel.code = data.data.code ? data.data.code : '';
          this.mainModel.description = data.data.description
            ? data.data.description
            : '';
          this.mainModel.importReason = data.data.importReason
            ? data.data.importReason
            : '';
          this.mainModel.storeName = data.data.storeName
            ? data.data.storeName
            : '';
          this.mainModel.storeId = data.data.storeId;
          this.mainModel.providerName = data.data.providerName
            ? data.data.providerName
            : '';
          this.mainModel.providerId = data.data.providerId;
          this.mainModel.createdBy = data.data.createdByName
            ? data.data.createdByName
            : '';
          this.searchEmployee = data.data.inChargeEmployeeName
            ? data.data.inChargeEmployeeName
            : '';
          this.mainModel.inChargeEmployeeId = data.data.inChargeEmployeeId;
          this.mainModel.companyId = data.data.companyId;
          this.mainModel.createdAt = data.data.createdAt
            ? moment(data.data.createdAt).format('HH:mm:ss DD/MM/YYYY')
            : '';
          this.mainModel.discountAmount = data.data.discountAmount
            ? data.data.discountAmount
            : 0;
          this.mainModel.vatAmount = data.data.vatAmount
            ? data.data.vatAmount
            : 0;
          this.mainModel.isVAT = data.data.isVAT;
          this.mainModel.discountType = data.data.discountType
            ? data.data.discountType
            : 1;
          this.mainModel.vatType = data.data.vatType ? data.data.vatType : 2;
          this.mainModel.cashAmount = data.data.cashAmount
            ? data.data.cashAmount
            : 0;
          this.mainModel.cashAccountCode = data.data.cashAccountCode
            ? data.data.cashAccountCode
            : '';
          this.mainModel.transferAmount = data.data.transferAmount
            ? data.data.transferAmount
            : 0;
          this.mainModel.transferAccountCode = data.data.transferAccountCode
            ? data.data.transferAccountCode
            : '';
          this.mainModel.payDate = data.data.payDate
            ? moment(data.data.payDate).format('DD-MM-YYYY')
            : '';
          this.mainModel.vatDate = data.data.vatDate
            ? moment(data.data.vatDate).format('DD-MM-YYYY')
            : '';
          this.mainModel.cashAccountName = data.data.cashAccountName
            ? data.data.cashAccountName
            : '';
          this.mainModel.transferAccountName = data.data.transferAccountName
            ? data.data.transferAccountName
            : '';
          this.mainModel.vatCode = data.data.vatCode ? data.data.vatCode : '';
          this.mainModel.importType = data.data.type ? data.data.type : 1;
          this.listProductStock = data.data.details.map((item) => {
            const totalAmount =
              item.quantity * item.unitPrice - item.discountAmount;
            return {
              ...item,
              totalAmount: totalAmount,
              isNew: this.mainModel.isNew,
            };
          });
          if (this.mainModel.isNew) {
            this.mainModel.id = null;
            this.mainModel.status = PURCHASE_ORDER_STATUS.NEW;
          }

          if (
            this.mainModel.status === PURCHASE_ORDER_STATUS.NEW ||
            this.mainModel.status === PURCHASE_ORDER_STATUS.PO_HEAD_CANCEL ||
            this.mainModel.status === PURCHASE_ORDER_STATUS.ACCOUNTANT_CANCEL
          ) {
            this.fieldProduct.push({
              key: 'actions',
              label: '',
              thStyle: {
                fontWeight: 600,
                color: '#181c32',
                textAlign: 'center',
                width: '5%',
              },
              tdClass: 'text-center',
            });
          }

          this.onChangeVat();
        })
        .catch(({ response }) => {
          makeToastFaile(response.$error);
        });
    },
    checkViewStockSlip() {
      if (this.mainModel.status === PURCHASE_ORDER_STATUS.ACCOUNTANT) {
        return true;
      }

      if (this.mainModel.status === PURCHASE_ORDER_STATUS.PURCHASE) {
        return true;
      }

      return false;
    },
    onInputChangeEmployee(text) {
      if (!text) {
        text = '';
      }
      this.mainModel.inChargeEmployeeName = text;
      /* Full control over filtering. Maybe fetch from API?! Up to you!!! */
      const filteredData = this.optionsEmployee
        .filter((item) => {
          const nameNoneSign = xoa_dau(item.name);
          return (
            item.name.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            nameNoneSign.toLowerCase().indexOf(text.toLowerCase()) > -1
          );
        })
        .slice(0, this.limit);

      this.filteredOptionsEmployee = [...filteredData];
    },
    onSelectedEmployee(option) {
      this.mainModel.inChargeEmployeeName = option.item.name;
      this.mainModel.inChargeEmployeeId = option.item.id;
    },
    fetchEmployee() {
      ApiService.get('employees/getAll').then((data) => {
        const employees = data.data.data;
        this.optionsEmployee = employees.map((element) => {
          return {
            id: element.id,
            name: element.fullName,
            suggestionName: element.fullName,
          };
        });
      });
    },
    getPrevPriceByProductId(productId) {
      ApiService.get(`purchase-order/prev-price/${productId}`).then(
        ({ data }) => {
          if (data.status === 1) {
            const item = this.selectedProducts.find(
              (product) => product.id === productId
            );
            if (item) item.prevUnitPrice = data.data;
          }
        }
      );
    },
    checkEdit() {
      const statusEditable = [
        PURCHASE_ORDER_STATUS.NEW,
        PURCHASE_ORDER_STATUS.PO_HEAD_CANCEL,
        PURCHASE_ORDER_STATUS.ACCOUNTANT_CANCEL,
        PURCHASE_ORDER_STATUS.ACCOUNTANT,
      ];
      if (statusEditable.includes(this.mainModel.status)) {
        return true;
      }

      return false;
    },
    async loadOptions({ action, searchQuery, callback }) {
      if (action === ASYNC_SEARCH) {
        const result = await this.fetchProduct(searchQuery);
        const options = (result || []).map((element) => {
          return {
            id: element.productId,
            label: element.productName,
            customLabel: '',
            productName: element.productName,
            productCode: element.productCode,
            barCode: element.barCode,
            salePowerWeek: element.totalSaleWeek,
            salePowerMonth: element.totalSaleMonth,
            prevUnitPrice: 0,
            unitPrice: element.originalPrice ? element.originalPrice : 0,
            quantity: 1,
            quantityApprove: 1,
            discountAmount: 0,
            totalAmount: element.originalPrice ? element.originalPrice : 0,
            inStockQuantity: element.quantityInStock
              ? element.quantityInStock
              : 0,
            salePrice: element.sellingPrice ? element.sellingPrice : 0,
            productType: element.productType,
          };
        });
        callback(null, options);
      }
    },
    onAddProduct() {
      if (!this.selectedProducts || !this.selectedProducts.length) {
        return makeToastFaile('Vui lòng chọn sản phẩm muốn thêm!');
      }

      const isCombo = this.selectedProducts
        .map((product) => product.productType)
        .includes(PRODUCT_TYPE.PRODUCT_COMBO);

      if (isCombo) {
        return makeToastFaile('Không hỗ trợ nhập kho sản phẩm combo');
      }

      const isService = this.selectedProducts
        .map((product) => product.productType)
        .includes(PRODUCT_TYPE.PRODUCT_SERVICE);

      if (isService) {
        return makeToastFaile('Không hỗ trợ nhập kho sản phẩm dịch vụ');
      }

      const products = this.selectedProducts.map((product) => {
        return {
          ...product,
          productId: product.id,
          id: v4(),
          isNew: true,
        };
      });

      this.listProductStock = products.concat(this.listProductStock);

      this.selectedProducts = [];
    },
    reportClick: async function () {
      const url = `${BASE_URL}purchase-order/export-excel-detail/${this.mainModel.id}`;
      await axios({
        url: url,
        method: 'GET',
        responseType: 'blob',
      }).then((response) => {
        const fileName = fileDownload.getFileName(response);
        fileDownload.createFile(response.data, fileName);
      });
    },
    getMinPriceByProductId(productId) {
      ApiService.get(`purchase-order/min-price/${productId}`).then(
        ({ data }) => {
          if (data.status === 1) {
            const item = this.selectedProducts.find(
              (product) => product.id === productId
            );
            if (item) {
              item.unitPrice = data.data;
              item.prevUnitPrice = data.data;
            }
          }
        }
      );
    },
    getPowerSaleByProductId(productIds) {
      const params = {
        productIds: productIds,
        storeId: this.mainModel.storeId,
      };

      ApiService.query(`purchase-order/power-sale-by-product`, { params }).then(
        ({ data }) => {
          if (data.status === 1) {
            const items = data.data;
            items.forEach((item) => {
              const productData = this.listProductStock.find(
                (productStock) => productStock.productId === item.productId
              );
              if (productData) {
                productData.salePowerWeek = item.totalSaleWeek;
                productData.salePowerMonth = item.totalSaleMonth;
                productData.inStockQuantity = item.inStockQuantity;
              }
            });
          }
        }
      );
    },
    fetchCompany: async function () {
      ApiService.get('/company').then((response) => {
        this.listCompany = response.data.data;
        this.listCompany.unshift({
          id: null,
          name: 'Chọn doanh nghiệp',
        });
      });
    },
    onChangeVat() {
      const vatTableField = this.fieldProduct.find(
        (field) => field.key === 'unitPrice'
      );
      if (vatTableField) {
        vatTableField.label = this.mainModel.isVAT
          ? 'Đơn giá (VAT)'
          : 'Đơn giá';
      }
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/sass/pages/wizard/wizard-4.scss';
</style>
